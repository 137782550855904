.login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 35px;
    margin-bottom: 100px;
}
.login__title {
    font-size: 2.2rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-image: linear-gradient(135deg, #505fc9, #d97ffd);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.login__lead {
    font-size: 1.4rem;
    margin-bottom: 1rem;
    color: gray;
}
.login__form, .login__form__group {
    margin-bottom: 1rem;
}
.login__form__input {
    font-size: 1rem;
    padding: 0.7rem 1.5rem;
    outline-color: rgb(100, 158, 197);
    color: #282d32;
}
.link__to__Signup, .link__to__resetPassword {
    font-size: 1rem;
}
.login__link, .reset__password__link {
    text-decoration: none;
    font-weight: 600;
}

.login__button__main {
    width: 100%;
    cursor: pointer;
    text-transform: none !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    font-family: 'Roboto', sans-serif !important;
}