.admissionProcess {
    flex: 6;
    background: linear-gradient(58deg, #233329,#6ccae4);
    display: inline-block;
    justify-content: space-around;
    width: 350px;
    color: white;
    margin-top: 10px;
    padding: 10px 10px 80px 10px;
    position: relative;
}
.admissionProcess__hr {
    width: 60%;
    margin: auto;
    margin-bottom: 20px;
}
.admissionProcess__title {
    margin-top: 20px;
    letter-spacing: 1px;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 10px;
}
.admissionProcess__title2 {
    letter-spacing: 0.7px;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 5px;
    margin-top: 30px;
}
.admissionProcess__note {
    padding: 0px 10px 0px 10px;
    letter-spacing: 1px;
    font-size: 0.6rem;
    margin-bottom: 5px;
    text-align: initial;
}
.admissionProcess ul {
    letter-spacing: 0.7px;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    text-align: initial;
}

.admissionProcess__1st__li {
    margin-top: 10px;
}
.admissionProcess li {
    text-align: initial;
}
.ad__sunIcon {
    color: white;
}
.admissionProcess__icons {
    font-size: 15px !important;
    margin-bottom: 4px;
}
.admissionProcess__li {
    font-size: 0.8rem;
    padding: 0px 30px 0px 30px;
}
/****Large Devices****/
@media (min-width: 1200px) {
    .admissionProcess {
        width: 420px;
    }
}

.admissionProcess__icons__bx {
    position: absolute;
    bottom: 5%;
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin: 0px 20px 0px 20px;
}
.admissionProcess__span {
    display: flex;
    text-align: start;
    font-weight: 500;
    padding: 0px 4px 0px 10px;
    font-size: 0.9rem;
    letter-spacing: 0.5px;
}