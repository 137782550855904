.alert {
    width: 100%;
    font-size: 1.6rem;
    color: white !important;
    padding: 1rem 2rem;
    position: absolute !important;
    top: 100%;
    margin: 0 !important;
    font-weight: 600;
}
.alert__success {
    background-color: #78eb64 !important;
    width: 100% !important;
}
.alert__error {
    background-color: red !important;
    width: 100% !important;
}
.alert__warning {
    background-color: orange !important;
    width: 100% !important;
}