.premSideBar {
    flex: 6;
    background: linear-gradient(58deg, #233329,#7b68ee);
    display: inline-block;
    justify-content: space-around;
    width: 350px;
    color: #fdfeff;
    margin-top: 10px;
    padding: 10px 10px 80px 10px;
    position: relative;
}
.premSideBar__title {
    margin-top: 20px;
    letter-spacing: 1px;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
    padding: 10px;
}
.premSideBar__note {
    padding: 10px;
    letter-spacing: 1px;
    font-size: 0.6rem;
    text-align: initial;
}
.premSideBar ul {
    letter-spacing: 0.6px;
    font-size: 1rem;
    font-weight: 600;
    text-align: initial;
}
.premSideBar__pr {
    margin-bottom: 10px;
    letter-spacing: 0.7px;
    font-size: 0.8rem;
    font-weight: 600;
    width: 100%;
    padding: 10px;
    text-align: initial;
}
.premSideBar__ul li {
    text-align: inherit;
    font-size: 0.8rem;
}
.premSideBar__li {
    padding: 0px 30px 0px 30px;
}
.premSideBar__icons {
    font-size: 15px !important;
    margin-bottom: 4px;
}
@media (min-width: 1200px) {
    .premSideBar {
        width: 420px;
    }
}

.premuimSupport__sidebar__bx h2 {
    letter-spacing: 1px;
    font-size: 0.7rem;
    font-weight: 600;
    width: 100%;
    color: #fdfeff;
    text-align: center;
    text-align: initial;
    margin: 0px 10px 0px 10px;
}
.premuimSupport__icon {
	color: #fdfeff;
	margin-left: 5px;
}
.premsideBar__icons__bx {
    margin: 20px;
    display: flex;
    justify-content: space-between;
    position: absolute;
}