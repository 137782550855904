* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
}
.forms {
	color: #fdfeff;
	margin: 20px 0px 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.formsCard {
	background-color: #233329;
	width: 265px ;
	box-shadow: 0px 5px 17px #a8a7a7 !important;
	margin: 5px 10px 5px 10px;
}
.formsCard:hover {
	transform: scale(1.07);
}
.forms__display {
	width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
	height: 20vh;
}
.forms__img {
	width: 100%;
}
.forms__subhead {
	font-size: 1rem;
	padding: 10px 0px 10px 0px;
	text-align: center;
	background: linear-gradient(58deg, #233329,#7b68ee);
	letter-spacing: 0.7px;
	margin: 0;
}
.forms__link {
	text-decoration: none !important;
	color: #fdfeff;
}
.forms__link:hover {
	color: white;
}