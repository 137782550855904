.visaAssistProcess {
    flex: 6;
    background: linear-gradient(58deg, #233329,#c35cdd);
    display: inline-block;
    justify-content: space-around;
    width: 350px;
    color: #F6F6F6;
    margin-top: 10px;
    padding: 10px 10px 30px 10px;
}
.visaAssistProcess__hr {
    width: 60%;
    margin: auto;
    margin-bottom: 20px;
}
.visaAssistProcess__title {
    margin-top: 1.1rem;
    letter-spacing: 1px;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;
    width: 100%;
}
.visaAssistProcess__title2 {
    letter-spacing: 1px;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: initial;
    width: 100%;
    
}
.visaAssistProcess__note {
    letter-spacing: 1px;
    font-size: 0.6rem;
    text-align: initial;
}
.visaAssistProcess ul {
    letter-spacing: 1px;
    font-size: 1rem;
    font-weight: 600;
    text-align: initial;
    padding: 0;
}
.visaAssistProcess__pr {
    margin: 30px 0px 20px 10px;
    letter-spacing: 0.7px;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: initial;
}
.visaAssistProcess__ul li {
    text-align: initial;
    padding: 0px 30px 0px 30px;
}
.ad__sunIcon {
    color: #F6F6F6;
}
.visaAssistProcess__icons {
    font-size: 15px !important;
    margin-bottom: 4px;
}
@media (min-width: 1200px) {
    .visaAssistProcess {
        width: 420px;
    }
}

.visaAssist__left__2ndbx > p {
	padding: 10px;
	letter-spacing: 0.5px;
	font-size: 14px;
}