.resetPasswordConfirm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 35px;
}
.resetPasswordConfirm__title {
    font-size: 3.2rem;
    margin-top: 50px;
    margin-bottom: 40px;
    background-image: linear-gradient(135deg, #6a7bff, #d97ffd);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.resetPasswordConfirm__form, .resetPasswordConfirm__form__group {
    margin-bottom: 1rem;
}
.resetPasswordConfirm__form__input {
    font-size: 1.4rem;
    padding: 0.7rem 1.5rem;
    outline-color: rgb(100, 158, 197);
}
.resetPasswordConfirm__button__primary {
    width: 100%;
    cursor: pointer;
    text-transform: none !important;
    font-size: 30px !important;
    font-weight: 600 !important;
    font-family: 'Roboto', sans-serif !important;
}