* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
}
.visaRequirments {
    margin: 5px 10px 5px 5px;
    display: inline-block;
    background: linear-gradient(to right, #233329,#ce5cdd);
    display: inline-block;
    justify-content: space-around;
    width: 370px;
    color: #F6F6F6;
    padding: 0px 5px 0px 5px;
}
.visaRequirments__title {
    font-size: 14px;
    letter-spacing: 1px;
    padding: 30px 20px 20px 20px;
    text-align: center;
    font-weight: 500;
}

.visaRequirments__container {
    display: flex;
    justify-content: space-evenly;
}
.visaRequirments__container > ul {
    margin: 0;
    padding: 0;
}
.visaRequirments__ul__title {
    margin-bottom: 20px;
    text-align: initial;
    padding: 0px 15px 0px 15px;
}
.visaRequirments__li {
    font-size: 11px;
    width: 130px;
    margin-bottom: 8px;
    padding: 0px 15px 0px 15px;
}
.visaRequirments__note {
    font-size: 0.6rem;
    padding: 10px;
}
@media (min-width: 1200px) {
    .visaRequirments {
        width: 420px;
    }
}
@media (max-width: 389px) {
    .visaRequirments__li {
        padding: 0px 20px 0px 25px;
    }
    .visaRequirments {
        padding: 0px 30px 0px 30px;
    }
    .visaRequirments__ul__title {
        padding: 0px 35px 0px 35px;
    }
}