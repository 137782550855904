.welcomePageFooter {
    padding:50px 0;
    color:#f0f9ff;
    background-color:#282d32;
    margin-top: 10px;
  }
  
  .welcomePageFooter__linksContainer > h3 {
    margin-top:0;
    margin-bottom:12px;
    font-weight:bold;
    font-size:16px;
  }
  
  .welcomePageFooter ul {
    padding:0;
    list-style:none;
    line-height:1.6;
    font-size:14px;
    margin-bottom:0;
  }
  
  .welcomePageFooter__link {
    color:inherit;
    text-decoration:none;
    opacity:0.6;
    cursor: pointer;
  }
  
.welcomePageFooter__link:hover {
    opacity:0.8;
    color: white;
  }
  
  .welcomePageFooter .copyright {
    text-align:center;
    padding-top:24px;
    opacity:0.3;
    font-size:13px;
    margin-bottom:0;
  }
  .welcomePageFooter__row
  {
    display: flex;
    justify-content: space-around;
    width: 100%;
    align-items: center;
  }
  .welcomePageFooter__linksContainer {
    padding: 20px;
    min-height: 30vh;
  }
.welSmallBox {
  width: 40%;
}
.welcomepageFooter__desc {
  margin: 0;
}
.social__icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.social__icons:hover  {
  color: white;
}
.social__icons__li {
  font-size:20px;
  width:36px;
  display:inline-block;
  text-align:center;
  margin:0 8px;
  opacity:0.75;
}
.welcomePageFooter__incon:hover {
  color: white;
  cursor: pointer;
}
.welcomePageFooter__incon {
  color: rgb(212, 206, 206);
}
.welSmallBox p {
  opacity:0.6;
  margin-bottom:0;
}
.copyright {
  margin: 0;
}


/****Large Devices****/
@media (min-width: 1025px) and (max-width: 1200px) {

}

/****Medium  Devices****/
@media (min-width: 769px) and (max-width: 1024px) {
  .welcomePageFooter__linksContainer {
    min-height: 45vh;
	}
  .welcomePageFooter__row {
    display: flex;
	}
}

/****Small Devices****/
@media (max-width: 768px) {
  .welcomePageFooter__row {
    display: block;
    text-align: center;    
	}
  .welSmallBox {
    width: 100%;
    text-align: center;
  }
  .welcomePageFooter__hr {
    width: 80%;
    margin: auto;
  }
  .welcomePageFooter__linksContainer {
    min-height: 18vh;
  }
}