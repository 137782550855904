.loginHeader {
    padding: 13px 20px;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 5px 8px -9px rgba(0, 0, 0, 0.75);
    width: 100%;
    position: sticky;
    z-index: 100;
    background-color: #fdfeff;
    top: 0;
} 
.logo {
    position: relative;
    top: 10%;
    cursor: pointer;
}
.logo__link {
    text-decoration: none;
}
.logo__text {
    font-size: 0.6rem;
    font-weight: 500;
    letter-spacing: 0.2px;
    color: #282d32 !important;
    margin: 5px 0px 0px 0px;
}

.loginHeader__right {
    display: flex;
    position: relative;
    bottom: -1%;
    justify-content: space-around;
}
.middle__header__bx {
    display: flex;
}
.loginHeader__main__btns {
    display: flex;
    align-items: center;
}
.loginHeader__max__right {
    position: relative;
    top: 5px;
}
.loginHeader__loginButton > button, .loginHeader__signupButton > button, .loginHeader__logoutButton > button  {
    font-weight: 600;
    text-transform: none;
    color: #282d32;
    background-color: #fdfeff;
    border: none;
    margin: 0px 8px 0px 8px;
}
.logout__btn {
    color: #282d32;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
    background-color: #fdfeff;
    border: none;
    width: 100%;
    text-align: inherit;
    font-size: 14px;
}
.logout__btn:hover {
    background-color: #3a444e;
    color: #fdfeff;
}
.services__expand {
    color: #6ccae4;
}
.sortIcon__bx {
    position: relative;
    bottom: -13px;
    padding-bottom: 12.5px;
    margin-left: 41px;
}
.loginHeader__sortIcon {
    cursor: pointer;
    font-size: 2.5rem !important;
    position: relative;
    top: -14%;  
}
.sortIcon__dropdown {
    margin-top: 13px;
    text-align: initial !important;
    right: -50%;
}
.logedin__sortIcon {
    position: relative;
    color: rgba(142, 46, 211, 0.84) !important;
    top: -7px;
}
.loggedin__icon__bx {
    position: relative;
    bottom: -13px;
    padding-bottom: 11px;
    margin: 0px 10px 0px 48px;
}
.logged__sortIcon__dropdown {
    right: -80%;
    margin-top: 13px;
}

/****Large Devices****/
@media (min-width: 901px) {
    .loginHeader__loginButton, .loginHeader__signupButton, .loginHeader__logoutButton, .dropdown__btn {
        font-size: 19px;
    }
    .dropdown__services {
        top: 13px;
        padding-bottom: 24px;
    }
    .dropdown__services__content {
        margin-top: 13px;
    }
    .lang__selected  {
        margin: 0px 30px 0px 30px;
    }
    .middle__header__bx {
        margin: 0px 80px 0px 80px;
    }
    .loginHeader__left {
        margin: 0px 30px 0px 30px;
    }
}
/****Medium and Large Devices****/
@media (min-width: 746px) and (max-width: 900px) {
    .loginHeader__left {
        margin: 0px 30px 0px 30px;
    }
    .lang__selected  {
        margin: 0px 30px 0px 30px;
    }
    .loginHeader__loginButton, .loginHeader__signupButton, .loginHeader__logoutButton, .dropdown__btn {
        font-size: 12.5px ;
    }
    .dropdown__services {
        top: 14px;
    }
    .dropdown__services__content {
        margin-top: 14px;
    }
    .dropdown__services {
        padding-bottom: 26px;
    }
    .middle__header__bx {
        margin: 0px 50px 0px 50px;
    }
    .loginHeader__left {
        margin: 0px 30px 0px 30px;
    }
}
/****Small Devices****/
@media (min-width: 450px) and (max-width: 746px) {
    .loginHeader__loginButton, .loginHeader__signupButton, .loginHeader__logoutButton {
        display: none;
    }

    .dropdown__services {
        display: none;
    }
    .loginHeader__menuItem {
        font-size: 5px;
    }
    .lang__selected {
        margin: 0px 10px 0px 10px;
    }
    .middle__header__bx {
        margin: 0px 30px 0px 30px;
    }
}
@media (max-width: 450px) {
    .loginHeader__loginButton, .loginHeader__signupButton, .loginHeader__logoutButton {
        display: none;
    }

    .dropdown__services {
        display: none;
    }
    .loginHeader__menuItem {
        font-size: 5px;
    }
    .lang__selected {
        margin: 0px 10px 0px 10px;
    }
    .middle__header__bx {
        margin: 0px 0px 0px 15px;
    }
    /* .logo__link {
        position: relative;
        top: 10%;
    } */
    .logo {
        width: 120px;
        height: 25px;
    }
    .logo__text {
        font-size: 7px;
    }
    .loginHeader__left {
        position: relative;
        top: 5px;
    }
}
.dropdown__btn {
    background-color: #fdfeff;
    color: #282d32;
    border: none;
    font-weight: 600;
    cursor: pointer;
    
  }
  
.loginHeader__services__dropdown {
    position: relative;
    display: inline-block;
  }
.dropdown__content {
  display: none;
  position: absolute;
  width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(146, 146, 146, 0.2);
  z-index: 1;
}

.loginHeader__menuItem {
  font-weight: 500;
  color: #282d32;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
  background-color: #fdfeff;
  font-size: 12px;
}
.loginHeader__menuItem:hover {
  background-color: #3a444e;
  color: #fdfeff;
}
.loginHeader__services__dropdown:hover .dropdown__content {
  display: block;
}


.dropdown__services {
    position: relative;
    margin: 0px 8px 0px 8px;
}

.loginHeader__logout__btn {
    font-weight: 500;
    color: #282d32;
    text-decoration: none;
    display: block;
    cursor: pointer;
    background-color: #fdfeff;
    border: none;
    text-align: inherit;
    position: relative;
    top: -4.5%;
}

.lang__selected {
    display: flex;
    position: relative;
    top: 15px;
    cursor: pointer;
}
.lang__select__box {
    position: relative;
}
.lang__container {
    position: absolute;
    background-color: #fdfeff;
    top: 130%;
}
.loginHeader__select__menu {
    background-color: #fdfeff !important;
    padding: 0px !important;
}
.loginHeader__lang__btn {
    background-color: #fdfeff;
    border: none;
    font-family: 'Roboto', sans-serif !important;
    padding: 8px;
    width: 80px;
    font-size: 13px;
}
.loginHeader__lang__btn:hover {
    background-color: #282d32;
    color: #fdfeff;
}
