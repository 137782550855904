* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
}
.schoolCards {
	color: #fdfeff;
	margin: auto;
	margin-bottom: 4rem;
	height: 100%;
}
.schoolCard {
	background-color: #233329;
	width: 265px ;
    min-height: 390px !important;
	box-shadow: 0px 5px 17px rgb(168, 167, 167) !important;
	margin: 5px 5px 5px 5px;
}
.schoolCard__subheading {
	font-size: 2.2rem;
	margin-bottom: 4rem;
	text-align: center;
	font-weight: 600;
	letter-spacing: 1px;
	color: #233329;
}
.schoolCard__display {
	width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
	height: 20vh;
}
.schoolCard__info > ul > li {
    font-size: 12px;
}
.schoolCard__display {
	height: 25vh;
	position: relative;
}
.schoolCard__image{
	margin: auto;
	max-width: 130px;
	max-height: min-content !important;
}
.school__language__center {
	font-size: 1.8rem;
	margin-bottom: 1rem;
	padding: 10px 0px 10px 0px;
	text-align: center;
	background: linear-gradient(58deg, #233329,#7b68ee);
	letter-spacing: 0.7px;
}
.schoolCard__ul {
    margin-top: 10px;
	height: 12vh;
	padding: 0px 20px 0px 20px;
}
.schoolCard__icon {
    font-size: 15px !important;
    margin-bottom: 4px;
}
.school__course {
	font-size: 0.9rem;
	font-weight: 500;
	margin-bottom: 0.5rem;
	padding: 0 15px 0 15px;
	letter-spacing: 0.5px;
}
.schoolCard__info {
	position: relative;
}
.school__country {
	font-size: 0.9rem;
	font-weight: 500;
	margin-bottom: 0.5rem;
	padding: 0 15px 0 15px;
	letter-spacing: 0.5px;
	position: absolute;
	right: 0%;
}
.school__about {
	margin-top: 5px;
	font-size: 12px;
	padding: 10px 10px 0 15px;
	font-weight: 300;
}
.schools__hr {
	width: 80%;
	margin: auto;
}
@media (max-width: 380px) {
	.schoolCard__ul {
		padding: 0px 10px 0px 10px;
	}
	.school__language__center {
		font-size: 1.3rem;
	}
}