
.background__image {
    background-image: linear-gradient(rgba(92,31,135,0.84), rgba(70, 70, 136, 0.253), #F6F6F6), url('../assets/images/bg.jpg') !important;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    content: normal;
}
.welcomePage {
    background-color: #F6F6F6;
}

