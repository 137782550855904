.notfound {
    margin-top: 10rem;
}
.notfound__heading {
    font-size: 6.2rem;
    text-align: center;
    opacity: 0.7;
    font-weight: 200;
    margin-bottom: 4rem;
}
.notfound__paragraph {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 14rem;
}