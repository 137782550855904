* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
}
.applicationForm {
    background-color: rgb(247, 244, 244);
}
.wrapper {
    min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
}

.applicationForm__wrapper {
	background-color: white;
	padding: 25px;
	border-radius: 5px;
	width: 500px;
	box-shadow: 0px 5px 8px -6px rgba(0, 0, 0, 0.75);
	margin-top: 15px;
}
.applicationForm__title {
    margin-top: 30px;
	margin-bottom: 20px;
    text-align: center;
	font-size: 1.2rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
	background-image: linear-gradient(135deg, #505fc9, #9b59b6);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.passport__section__title {
    text-align: center;
	font-weight: 700;
	color: rgb(117, 117, 117);
	margin-bottom: 30px;
	margin-top: 20px;
}
.applicationForm__form {
	margin-top: 35px;
}
.form_wrap, .input_wrap:last-child{
	margin-bottom: 0 ;
}
.ukForm__form__label, .ukForm__text {
	display: block;
	margin-bottom: 3px;
	color: black;
	font-weight: 500;
}
.ukForm__section__question {
	margin-bottom: 3px;
	color: black;
	font-weight: 500;
	margin-top: 35px;
}
.expand__bt {
	color: #5AFF3D;
}
.expand__less__bt {
	color: rgb(226, 67, 67);
}
.form_wrap, .input_grp {
	display: flex;
	justify-content: space-between;
	margin-bottom: 10px;
}
.input_wrap__bt {
	margin-bottom: 10px;
}

.input_grp  input[type="text, email, date"]{
	width: 165px;
}
.ukForm__input__for__two {
	width: 200px;
}
.passport__input__radio {
	margin-top: 27px;
}
.applicationForm__form__textarea, .input__for__one {
	width: 100% ;
	border-radius: 3px;
	border: 1px solid #9597a6;
	padding: 10px;
	outline: none;
}
.input__radio__group {
	width: 100%;
}
.applicationForm__form__input {
	border-radius: 3px;
	border: 1px solid #9597a6;
	padding: 10px;
	outline: none;
}
.appform__hr {
	color: grey;
}
.appform__ul {
	padding: 8px 10px;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	background-color: rgb(247, 244, 244);
	box-shadow: 0px 5px 8px -8px rgba(0, 0, 0, 0.75);
}

.appform__il:first-child{
	margin-right: 15px;
}

.radio_wrap {
	position: relative;
	margin-bottom: 0;
	cursor: pointer;
}

.radio_wrap .input_radio{
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0;
}

.radio_wrap span{
	display: inline-block;
	font-size: 0.8rem;
	font-weight: 600;
	padding: 3px 20px;
	border-radius: 3px;
	color: #716254;
}

.input_radio:checked ~ span{
	background-color: #ebd0ce;
	box-shadow: 0px 5px 8px -8px rgba(0, 0, 0, 0.75);
}
.import__passport input[type="file"] {
	color: #9597a6;
}
.app__form__button {
    font-size: 1rem;
    padding: 1rem 2rem;
    width: 60%;
    margin: 0% 20% 0% 20%;
    background-image: linear-gradient(135deg, #6a7bff, #d97ffd);
	font-weight: 800;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    outline: none;
	box-shadow: 0px 5px 8px -6px rgba(0, 0, 0, 0.75);
	color: white;
}
.app__form__button:hover {
    background: linear-gradient(135deg, #505fc9, #9b59b6);
    color: white;
}
.appform__section__title {
	width: 160px;
	margin-left: auto;
    margin-right: auto;
	font-weight: 600;
	align-items: center;
	justify-content: center;
	color: rgb(117, 117, 117);
}
.select__op__container {
	font-size: 1rem;
	color:rgb(117, 117, 117);
	font-weight: 500;
}

.p__select__box {
	display: flex;
	width: 200px;
	flex-direction: column;
}
.p__select__box .p__option__container {
	width: 100%;
	background-color: rgb(247, 244, 244);
	box-shadow: 0px 5px 8px -8px rgba(0, 0, 0, 0.75);
	transition: all 0.4s;
  	border-radius: 8px;
  	overflow: hidden;
	padding: 0;
	width: 200px;

	order: 1;
}
.travel__p__option, .ukForm__selected {
	padding: 12px 24px;
	cursor: pointer;
}
.p__input_radio:checked ~ span {
	background-color: #ebd0ce;
	box-shadow: 0px 5px 8px -8px rgba(0, 0, 0, 0.75);
	font-weight: 600;
	padding: 3px 20px;
	border-radius: 3px;
	cursor: pointer;
}
.travel__p__option:hover {
	background-color: #ebd0ce;
	cursor: default;
}
.p__box__label {
	cursor: pointer;
}
.p__box__label .p__input_radio  {
	display: none;
}
.ukForm__selected {
	background-color: rgb(247, 244, 244);
	box-shadow: 0px 5px 8px -8px rgba(0, 0, 0, 0.75);	border-radius: 8px;
	margin-bottom: 8px;
	position: relative;

	order: 0;
}
.p__xpand__bt{
	position: absolute;
	height: 100%;
	top: 12px;
	margin-left: 30%;
	margin-right: 30%;
	color: #6ccae4;
	font-size: 1.5rem !important;
}
@media (max-width: 530px) {
	.radio_wrap span {
		font-size: 0.7rem;
	}
	.ukForm__section__question, .ukForm__form__label, .ukForm__input__for__two, .input__for__one, .select__op__container, .ukForm__selected, .ukForm__text {
		font-size: 0.8rem !important;
	}
	.p__select__box, .p__select__box .p__option__container {
		width: 145px;
	}
	.ukForm__input__for__two {
		width: 145px;
	}
	.ukForm__form__label, .ukForm__selected, .select__op__container, .input__for__one {
		font-size: 0.9rem;
	}
}
