* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	list-style: none;
}
.request__service__bx {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(247, 244, 244);
}
.request__service__form {
	padding: 25px;
	border-radius: 5px;
	width: 350px;
    margin-top: 10px;
    margin-bottom: 10px;
	background-color: white;
	box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.75);
}
.request__service__title {
    text-align: center;
	font-size: 1.2rem;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-weight: 700;
    margin-bottom: 30px;
	background-image: linear-gradient(135deg, #505fc9, #9b59b6);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.request__service__hr {
	margin-bottom: 25px;
}
.request__service__form__label {
	display: none;
}
.input_grpp{
	display: flex;
	justify-content: space-between !important;
	margin-bottom: 20px;
}

.request__service__form__input {
    border-radius: 3px;
	border: 1px solid #9597a6;
	padding: 10px;
	outline: none;
	width: 145px;
	font-size: 0.8rem;
}
.request__service__form__textarea, .subject__type{
	width: 100% !important;
	border-radius: 3px;
	border: 1px solid #9597a6;
	padding: 10px;
	outline: none;
	margin-bottom: 20px;
	font-size: 0.8rem;
}

.request__service__form__button {
    font-size: 1rem;
    padding: 1rem 2rem;
    width: 60%;
    margin: 0% 20% 0% 20%;
    background: linear-gradient(135deg, #505fc9, #9b59b6);
    color: white;
	font-weight: 800;
    border: none;
    cursor: pointer;
    margin-top: 1rem;
    outline: none;
}
.request__service__form__button:hover {
    background: #5AFF3D;
    color: white;
}
@media (min-width: 1200px) {
    .request__service__form {
		width: 400px;
	}
	.request__service__form__input {
		width: 170px;
		font-size: 1rem;
	}
	.request__service__form__textarea {
		font-size: 1rem;
	}
}