.serviceCard {
    min-height: 100vh;
}

.serviceCard__root {
    width: 265px;
    height: 400px;
    background: #17221b !important;
    margin-right: 20px;
    text-transform: none;
    box-shadow: 0px 5px 17px #233329 !important;
    margin: 20px;
    position: relative;
}
.serviceCard__mainTitle {
    text-align: center;
    color: #233329;
    letter-spacing: 0.6;
    font-weight: 700;
    font-size: 2.2rem;
    margin-bottom: 40px;
    margin-top: 40px;
}
.serviceCard__root:hover {
    transform: scale(1.07);
}
.serviceCard__img {
    height: 200px;
    filter: brightness(70%)
}
/* .serviceCard__box {
    position: relative;
} */
.serviceCard__title {
    font-weight: 800 !important;
    font-size: 1.9rem !important;
    color: white;
    position: absolute;
    top: 10%;
    bottom: 170px;
    text-align: center;
    padding: 10px;
    letter-spacing: 1px;
    width: 100%;  
    padding: 0px 15px 0px 15px;  
}
.serviceCard__desc {
    color: #F6F6F6 !important;
    text-align: left;
    position: absolute;
    top: 30%;
    font-size: 0.8rem !important;
    letter-spacing: 0.5px !important;
}
.serviceCard__button {
    margin: 0 !important;
    padding: 0 !important;
}
.serviceCard__learnMore{
    font-size: 0.8 !important;
    font-family: 'Lora', sans-serif !important;
    text-transform: none !important;
}
.serviceCard__link {
    text-decoration: none;
}
.serviceCard__ul, .serviceCard__box {
    padding: 0px !important;
}
.serviceCard__ul {
    position: relative;
    top: 95px;
    display: block;
}
.serviceCard__miniTitle {
    padding: 0px 15px 10px 15px ;
    font-size: 0.9rem;
    font-weight: 400;
    letter-spacing: 0.3px;
    text-align: initial;
}
.serviceCard__li {
    padding: 0px 5px 0px 5px;
    text-align: initial;
}
.serviceCard__btn__box {
    padding: 0px 5px 0px 5px;
    text-align: initial;
    margin: 10px 10px 0px 10px;
}
.serviceCard__li__Icon {
    font-size: 15px !important;
    margin-bottom: 4px;
}

.serviceCard__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.guide__li {
    padding: 25px 10px 25px 10px;
}
